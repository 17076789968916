import React, { ReactElement, useEffect, useState } from 'react'
import { Button, PageLevelBanner, PublicHeader } from '@digicert/dcone-common-ui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/pro-light-svg-icons'
import styles from './Welcome.module.scss'
import { ReactComponent as TlmCapabilityImage } from './assets/tlm-capability-image.svg'
import { ReactComponent as DtmCapabilityImage } from './assets/dtm-capability-image.svg'
import { ReactComponent as IotCapabilityImage } from './assets/iot-capability-image.svg'
import { ReactComponent as StmCapabilityImage } from './assets/stm-capability-image.svg'
import { translate } from '../../../shared/helpers/utils'
import { Translate, withLocalize } from 'react-localize-redux'
import { WelcomePromoProps, WelcomeProps } from './Welcome.type'
import { connect } from 'react-redux'
import Cookies from 'js-cookie'
import {
  CONTACT_US_URL,
  DCONE_LOCALE_COOKIE_NAME,
  DTM_URL,
  IOT_URL,
  STM_URL,
  TLM_URL
} from '../../../core/utils/constants'
import { fetchLocales } from '../../../core/actions/Settings.actions'
import { ILocale } from '../../../reducers/Reducers.interfaces'
import { useLocation } from 'react-router-dom'

const Welcome = (
  {
    fetchLocales,
    setActiveLanguage
  }: WelcomeProps): ReactElement => {
  const [language, setLanguage] = useState('English')
  const [locales, setLocales] = useState<ILocale[]>([])
  const [loaded, setLoaded] = useState(false)
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)
  const [managerName, setManagerName] = useState(searchParams.get('app') as string | 'mpki')

  const getManagerName = () => {
    return translate(`marketing.welcome.${managerName}.name`)
  }

  const productPromos: WelcomePromoProps = {
    title: translate(`marketing.promotions.${managerName}.title`),
    promos: [
      {
        title: translate(`marketing.promotions.${managerName}.promo1`),
        description: translate(`marketing.promotions.${managerName}.promo1Description`)
      },
      {
        title: translate(`marketing.promotions.${managerName}.promo2`),
        description: translate(`marketing.promotions.${managerName}.promo2Description`)
      },
      {
        title: translate(`marketing.promotions.${managerName}.promo3`),
        description: translate(`marketing.promotions.${managerName}.promo3Description`)
      }
    ]
  }

  const handleLanguageSelection = (language: string, countryCode: string) => {
    setLanguage(language)
    // prevents flash of untranslated content until active lang gets updated
    setLoaded(false)
    Cookies.set(DCONE_LOCALE_COOKIE_NAME, countryCode)
    setActiveLanguage(countryCode.split('_')[0])
    window.location.reload()
  }

  const navigateToContact = (e) => {
    e.preventDefault()
    window.open(CONTACT_US_URL, '_blank')
  }

  const navigateToManager = () => {
    switch (managerName) {
      case 'documentmanager':
        return DTM_URL;
      case 'mpki' :
        return TLM_URL;
      case 'iot' :
        return IOT_URL;
      case 'signingmanager' :
        return STM_URL;
    }
  }

  const renderCapabilityImage = () => {
    switch (managerName) {
      case 'documentmanager':
        return <DtmCapabilityImage/>;
      case 'mpki' :
        return <TlmCapabilityImage/>;
      case 'iot' :
        return <IotCapabilityImage/>;
      case 'signingmanager' :
        return <StmCapabilityImage/>;
    }
  }

  useEffect(() => {
    fetchLocales().then((response) => {
      setLocales(response)
      setLoaded(true)
    })
  }, []);

  useEffect(() => {
    const langCode = Cookies.get(DCONE_LOCALE_COOKIE_NAME)
    if(locales && langCode) {
      const selectedLanguage = locales.find(locale => locale.id === langCode)
      if(selectedLanguage){
        setLanguage(selectedLanguage.name)
        setActiveLanguage(selectedLanguage.id.split('_')[0])
      }
    }
  }, [locales]);

  useEffect(() => {
    if(managerName === null || managerName === '') {
      setManagerName('mpki')
    }
  }, []);

  if(loaded) {
    return (
      <section className={styles.welcome}>
        <PublicHeader
          defaultLanguage={language}
          onLanguageSelection={handleLanguageSelection}
        />

        <div className={styles.header}>
          <div>
            <h1 className={styles.headline}>{translate(`marketing.welcome.${managerName}.name`)}</h1>
            <p>{translate(`marketing.welcome.${managerName}.description`)}</p>
          </div>
        </div>

        <div className={styles.content}>
          {searchParams.get('message')
            ? (
              <div className={styles.noAppAccess}>
                <PageLevelBanner
                  bannerType="info"
                  title={translate('marketing.noAppAccess')}
                  description={translate('marketing.noAppAccessMessage')}
                />
              </div>
            )
            : (
              <div className={styles.actions}>
                <h2
                  className={styles.sectionTitle1}>{translate(`marketing.welcome.${managerName}.nextLicenseAllocation`)}</h2>
                <p className={styles.description}>
                  <Translate id={`marketing.welcome.${managerName}.requireLicenseMessage`}
                             data={{managerName: getManagerName()}}/>
                </p>
                <Button role='link' onClick={navigateToContact}>
                  <Translate id='common.form.buttons.contactUs'/>
                </Button>
              </div>
            )
          }

          <div className={styles.productPromos}>
            <h2 className={styles.sectionTitle1}>{productPromos.title}</h2>
            <div className={styles.container}>
              <div>
                {productPromos.promos.map((item, index) => {
                  return (
                    <div className={styles.promo} key={index}>
                      <p className={styles.title}><FontAwesomeIcon className={styles.check} icon={faCheck}/>{item.title}
                      </p>
                      <p className={styles.description}>{item.description}</p>
                    </div>
                  )
                })}
              </div>
              {renderCapabilityImage()}
            </div>

            <a href={navigateToManager()} className={styles.learnMore}>
              <Translate id={`marketing.welcome.${managerName}.learnMore`} data={{managerName: getManagerName()}}/>
            </a>
          </div>
        </div>
      </section>
    )
  }

  else return <></>
}

export default connect(
  null,
  { fetchLocales }
)(withLocalize(Welcome))
