import { PageLevelBanner } from '@digicert/dcone-common-ui'
import React, { ReactElement, useCallback, useEffect, useState } from 'react'

import { DTAClientErrors } from 'core/services/dta/DTAClientErrors'
import { PKIClientInstance } from 'core/services/dta/DTAClientInstane'
import { DTAInitializeRetryTimeout } from 'core/services/dta/dta.constants'
import { getDTAErrorBanner, checkDCKSPrerequisites } from './DTAInitializer.helper'
import { Translate } from 'react-localize-redux'
import { getErrorMessage } from 'shared/helpers/ErrorMessage.helpers'
import { checkMinimalVersion, throwIfNotSupportedPlatform } from '../../../helpers/DesktopClient.helpers'
import { DCKSUninitializedError, minimalDTADCKSVersion, minimalDTAVersion } from './DTAInitializer.constants'
import { DCKSInitialize } from './DCKSInitialize'

export interface DTAInitializerProps {
  onInitialize?: (initialized: boolean) => void
  unInitializeDdc?:(initialized: boolean) => void
  needToInitialize: boolean
  children?: any
  keyStoreLocation: string
}

const specificErrors = [DCKSUninitializedError]

export const DTAInitializerComponent = (props: DTAInitializerProps): ReactElement => {
  const { onInitialize = flag => {}, unInitializeDdc = flag => {},needToInitialize, children, keyStoreLocation} = props
  const [reInitTrigger, setReInitTrigger] = useState(false)
  const [notInstalledCount, setNotInstalledCount] = useState(0)
  const [error, setError] = useState<string>('')
  const [isInitialized, setIsInitialized] = useState(false)
  const [isSilentErrorHandling, setSilentErrorHandling] = useState(true)
  const notInstalledThreshold = 3
  const isDCKS = keyStoreLocation === 'DIGICERT_SOFTWARE'
  const minimalVersion = isDCKS ? minimalDTADCKSVersion : minimalDTAVersion


  const initialize = useCallback(async () => {
    return Promise.resolve()
    .then(throwIfNotSupportedPlatform)
    .then(() => PKIClientInstance.get())
    .then(instance => checkMinimalVersion(instance!.clientVersion, minimalVersion))
    .then(() => checkDCKSPrerequisites(props.keyStoreLocation))
    .then(() => {
      setSilentErrorHandling(false)
      setIsInitialized(true)
      onInitialize(true)
      unInitializeDdc(false)
    })
    .catch(error => {
      const parsedError = getErrorMessage(error)
      if (parsedError === DTAClientErrors.NOT_INSTALLED && isSilentErrorHandling) {
        if (notInstalledCount >= notInstalledThreshold) {
          setError(parsedError)
          setSilentErrorHandling(false)
        } else {
          setNotInstalledCount(notInstalledCount + 1)
        }
      } else {
        setError(parsedError)
        setSilentErrorHandling(false)
      }
      throw error
    })
  }, [isSilentErrorHandling, notInstalledCount])

  useEffect(() => {
    (async () => {
      if (needToInitialize && !isInitialized) {
        await initialize().catch(() => setTimeout(() => setReInitTrigger(t => !t), DTAInitializeRetryTimeout))
      }
    })()
  }, [needToInitialize, isInitialized, reInitTrigger])

  if (!needToInitialize) {
    return <>{children}</>
  }

  return (
    <>
      {!isInitialized ? (
        <>
          {isSilentErrorHandling && (
            <PageLevelBanner
              bannerType='info'
              title={<Translate id={'dta.genericBannerTitle'} />}
              description={<Translate id={'dta.info.initializing'} />}
            />
          )}
          {error && !specificErrors.includes(error) && getDTAErrorBanner(error as string)}
          {error && error === DCKSUninitializedError && <DCKSInitialize />}
        </>
      ) : (

        <>
          {children}</>
      )}
    </>
  )
}