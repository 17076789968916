import { IFilters, ISort } from 'core/actions/Actions.interfaces'

const getSortingParamsAsLink = (params: ISort): string => {
  if (!params.sort) return ''
  return params.sort.reduce((result, item) => `${result}&sort=${item.columnKey}`, '')
}

function parseFiltersParams (filters: IFilters): string {
  return Object.keys(filters).reduce((result, filterName) => {
    return filters[filterName] !== undefined ? `${result}&${filterName}=${filters[filterName]}` : result
  }, '')
}

const PREFIX_API_V1: string = '/account/ui-api/v1'
const PREFIX_API_V2: string = '/account/ui-api/v2'
const CA_API: string = '/certificate-authority/api/v1'
const IMAUTH_API_V1: string  = '/imauth/api/v1'
const EM_API: string = '/mpki/api/v1'

export default class HTTPUrlHelper {
  public static getSetupUserAPI (): string {
    return `${PREFIX_API_V1}/setup/user`
  }

  public static getSetupLicenseAPI (): string {
    return `${PREFIX_API_V1}/setup/license`
  }

  public static getFeaturesAPI (): string {
    return `${PREFIX_API_V1}/feature-flag/features`
  }

  public static getRolesAPI (): string {
    return `${PREFIX_API_V1}/role`
  }

  public static getRoleByIdAPI (id: string): string {
    return `${PREFIX_API_V1}/role/${id}`
  }

  public static getRoleByIdAndAccountAPI (id: string, accountId: string): string {
    return `${PREFIX_API_V1}/role/${id}?account_id=${accountId}`
  }

  public static getUserCountByRole (id: string): string {
    return `${PREFIX_API_V1}/role/${id}/users`
  }

  public static archiveRoleByIdAPI (id: string): string {
    return `${PREFIX_API_V1}/role/${id}`
  }

  public static getUniqueRoleNameAPI (application_id: string, name: string, account_id: string): string {
    return `${PREFIX_API_V1}/role/unique?application_id=${application_id}&name=${name}&account_id=${account_id}`
  }

  public static getPermissionGroups (applicationId: string, accountId: string): string {
    return `${PREFIX_API_V1}/permissiongroup?application_id=${applicationId}&account_id=${accountId}`
  }

  public static getUpdateLicenseAPI (): string {
    return `${PREFIX_API_V1}/license`
  }

  public static getDtaLicenseAPI (): string {
    return `${PREFIX_API_V1}/license/dta`
  }

  public static getLicenseDetailsAPI (): string {
    return `${PREFIX_API_V1}/license/details`
  }

  public static getLocalizationAPI (locale: string): string {
    return `${PREFIX_API_V1}/translation?locale=${locale}`
  }

  public static getLocalesAPI () : string {
    return `/account/api/v1/locales`
  }

  public static getUpdateLocaleAPI (userId: string, locale: string) : string {
    return `${PREFIX_API_V1}/user/${userId}/locale/${locale}`
  }

  public static getTokenAPI (): string {
    return `${PREFIX_API_V1}/auth/login`
  }

  public static getRefreshTokenAPI (): string {
    return `/imauth/api/v1/refresh`
  }

  public static getProfileApi (): string {
    return `${PREFIX_API_V1}/auth/me`
  }

  public static getUserProfileApi (hideUnavailableApplications: boolean): string {
    return `/account/api/v1/user/me?hide_unavailable_applications=${hideUnavailableApplications}`
  }

  public static getPermissionsAPI (accessScope?: string, accountId?: string): string {
    let url = `${PREFIX_API_V1}/permission`
    if (accessScope) {
      url = url + `?accessScope=${accessScope}`
      if(accountId) {
        url = url + `&accountId=${accountId}`
      }
    }
    return url
  }

  public static getPasswordVerificationAPI (userId: string): string {
    return `${PREFIX_API_V1}/user/${userId}/verify`
  }

  public static getUserAPI (): string {
    return `${PREFIX_API_V1}/user`
  }

  public static getServiceUserAPI (): string {
    return `${PREFIX_API_V1}/service-user`
  }

  public static getEditServiceUserAPI (id: string): string {
    return `${PREFIX_API_V1}/service-user/${id}`
  }

  public static getEnableDisableServiceUserAPI (id: string): string {
    return `${PREFIX_API_V1}/service-user/${id}/enable`
  }

  public static resendPasswordResetLink (userId: string): string {
    return `${PREFIX_API_V1}/user/${userId}/email`
  }

  public static sendSetupCacBasedLoginEmail (userId: string): string {
    return `${PREFIX_API_V1}/user/${userId}/sendSetupCacBasedLoginEmail`
  }

  public static updateManagerPreference (userId: string, appUrl: string): string {
    return `${PREFIX_API_V1}/user/${userId}/defaultManager?appUrl=${appUrl}`
  }

  public static mfaLink (userId: string): string {
    return `${PREFIX_API_V1}/user/${userId}/mfa`
  }

  public static resendSsoEmail (userId: string): string {
    return `${PREFIX_API_V1}/user/${userId}/resendSsoEmail`
  }

  public static createStdUserLink (userId: string): string {
    return `${PREFIX_API_V1}/user/${userId}/user`
  }

  public static getUserByIdAPI (id: string): string {
    return `${PREFIX_API_V1}/user/${id}`
  }

  public static getValidateCsvAPI (): string {
    return `${PREFIX_API_V1}/user/bulk/validate`
  }

  public static getUserApplicationsAPI (): string {
    return `${PREFIX_API_V1}/user/applications`
  }

  public static getUserApplicationsWithAccountAPI (accountId: string): string {
    return `${PREFIX_API_V1}/user/applications?accountId=${accountId}`
  }

  public static getCreateBulkUserAPI (): string {
    return `${PREFIX_API_V1}/user/bulk`
  }

  public static updateAgreement (id: string): string {
    return `${PREFIX_API_V1}/user/${id}/agreement`
  }

  public static getUploadCertificate (id: string): string {
    return `${PREFIX_API_V1}/user/${id}/client-auth-certificate/upload`
  }

  public static getLimitedUsersAPI (params: ISort, filters?: IFilters): string {
    const { limit, offset } = params
    const sortingParams = getSortingParamsAsLink(params)
    const filtersParams = filters ? parseFiltersParams(filters) : ''

    return `${PREFIX_API_V1}/user?limit=${limit}&offset=${offset}${sortingParams}${filtersParams}`
  }

  public static getClientAuthCertsAPI (params: ISort, id: string, filters?: IFilters) : string {
    const { limit, offset } = params
    const sortingParams = getSortingParamsAsLink(params)
    const filtersParams = filters ? parseFiltersParams(filters) : ''

    return `${PREFIX_API_V1}/user/${id}/client-auth-certificate?limit=${limit}&offset=${offset}${sortingParams}${filtersParams}`
  }

  public static generateClientAuthCertAPI (id: string) {
    return `${PREFIX_API_V1}/user/${id}/client-auth-certificate/`
  }

  public static generateClientAuthCertForEnrollmentAPI (id: string) {
    return `${PREFIX_API_V1}/enrollment/${id}/client-auth-certificate/`
  }

  public static enableClientAuthCertAPI (id: string, userId: string) {
    return `${PREFIX_API_V1}/user/${userId}/client-auth-certificate/${id}/enable`
  }

  public static generateClientAuthCertServiceUserAPI (id: string) {
    return `${PREFIX_API_V1}/service-user/${id}/client-auth-certificate/`
  }

  public static uploadClientAuthCertServiceUserAPI (id: string) {
    return `${PREFIX_API_V1}/service-user/${id}/client-auth-certificate/upload`
  }

  public static enableClientAuthCertServiceUserAPI (id: string, userId: string) {
    return `${PREFIX_API_V1}/service-user/${userId}/client-auth-certificate/${id}/enable`
  }

  public static getCustomerAPI (): string {
    return `${PREFIX_API_V1}/customer`
  }

  public static getAccountsAPI (): string {
    return `${PREFIX_API_V1}/account`
  }

  public static getAccountByIdAPI (id: string): string {
    return `${PREFIX_API_V1}/account/${id}`
  }

  public static getAccountTemplateAPI (id: string): string {
    return `${PREFIX_API_V1}/account/${id}/template`
  }

  public static getAccountProfileAPI (id: string): string {
    return `${PREFIX_API_V1}/account/${id}/profile`
  }

  public static getTenantsAPI (): string {
    return `${PREFIX_API_V1}/account`
  }

  public static getExpiringTenantsAPI (): string {
    return `${PREFIX_API_V1}/account/expiringAccounts`
  }

  public static getTenantByIdAPI (id: string): string {
    return `${PREFIX_API_V1}/account/${id}`
  }

  public static getEnrollmentByIdAPI (id: string, code: string): string {
    return `${PREFIX_API_V1}/enrollment/${id}/${code}`
  }

  public static getRedeemEnrollmentByIdAPI (id: string): string {
    return `${PREFIX_API_V1}/enrollment/${id}`
  }

  public static getAccountOuthClientsAPI (): string {
    return `${PREFIX_API_V1}/oauth/client`
  }

  public static getAllAccountOuthClientsAPI (account?: string): string {
    if(account) {
      return `${PREFIX_API_V1}/oauth/clients/account/${account}` 
    }
    return `${PREFIX_API_V1}/oauth/clients`
  }

  public static getAddAccountOauthConfigAPI (id: string, oauthClientId: string): string {
    return `${PREFIX_API_V1}/account/${id}/oauth/${oauthClientId}/config`
  }

  public static getUpdateAccountOauthConfig (accountId: string, oauthClientId: string, configId: string): string {
    return `${PREFIX_API_V1}/account/${accountId}/oauth/${oauthClientId}/config/${configId}`
  }

  public static getRegenerateOauthClientSecret (oauthClientId: string) : string {
    return `${PREFIX_API_V1}/oauth/client/${oauthClientId}/secret`
  }

  public static getOauthCLientApi (oauthClientId: string) : string {
    return `${PREFIX_API_V1}/oauth/client/${oauthClientId}`
  }

  public static getOauthCLientStatusApi (oauthClientId: string) : string {
    return `${PREFIX_API_V1}/oauth/client/${oauthClientId}/status`
  }

  public static getEditSigningAPI (id: string): string {
    return `${PREFIX_API_V1}/account/${id}/standard-signin`
  }

  public static getEnableMfaAPI (id: string): string {
    return `${PREFIX_API_V1}/account/${id}/enable-mfa`
  }

  public static getUnlinkAccountApi (accountId: string): string {
    return `${PREFIX_API_V1}/account/${accountId}/unlink`
  }

  public static getEnableCacAPI (id: string): string {
    return `${PREFIX_API_V1}/account/${id}/enable-cac`
  }

  public static getEnrollmentMethodsAPI (): string {
    return `${PREFIX_API_V1}/enrollment-method`
  }

  public static getKeyStoreTypesAPI (): string {
    return `${PREFIX_API_V1}/keystore-type`
  }

  public static getOrganizationsAPI (): string {
    return `${PREFIX_API_V1}/organization`
  }

  public static getOrganizationByIdAPI (id: string): string {
    return `${PREFIX_API_V1}/organization/${id}`
  }

  public static getLimitedOrganizationsAPI (params: ISort, filters?: IFilters): string {
    const { limit, offset } = params
    const sortingParams = getSortingParamsAsLink(params)
    const filtersParams = filters ? parseFiltersParams(filters) : ''

    return `${PREFIX_API_V1}/organization?limit=${limit}&offset=${offset}${sortingParams}${filtersParams}`
  }

  public static getContactsAPI (organizationId: string): string {
    return `${PREFIX_API_V1}/organization/${organizationId}/contact`
  }

  public static getContactByIdAPI (organizationId: string, contactId: string): string {
    return `${PREFIX_API_V1}/organization/${organizationId}/contact/${contactId}`
  }

  public static getSeatTypesAPI (): string {
    return `${PREFIX_API_V1}/seat/types`
  }

  public static getSeatsAPI (): string {
    return `${PREFIX_API_V1}/seat`
  }

  public static getSeatByIdAPI (id: string): string {
    return `${PREFIX_API_V2}/seat/${id}`
  }

  public static getEnrollmentAPI (enrollmentId: string, enrollmentLinkSecret: string): string {
    return `${PREFIX_API_V2}/enrollment/${enrollmentId}/${enrollmentLinkSecret}`
  }

  public static getEnrollmentUpdatingAPI (id: string, enrollmentSecret: string): string {
    return `${PREFIX_API_V2}/enrollment/${id}/${enrollmentSecret}`
  }

  public static getEnrollmentStatusUpdatingAPI (id: string): string {
    return `${PREFIX_API_V2}/enrollment/${id}/status`
  }

  public static getDetailsViewEnrollments (seatId: string): string {
    return `${PREFIX_API_V2}/enrollment?seat_id=${seatId}`
  }

  public static getAccountSeatTypesAPI (): string {
    return `${PREFIX_API_V1}/seat/available`
  }

  public static getCAByIdAPI (filters: IFilters): string {
    const filtersParams = filters ? parseFiltersParams(filters) : ''
    return `${PREFIX_API_V1}/ca/${filters.id}?${filtersParams}`
  }

  public static getCABlobByIdAPI (id: string): string {
    return `${PREFIX_API_V1}/ca/${id}/blob`
  }

  public static getFilteredAPI (url: string, params: ISort, filters?: IFilters): string {
    const { limit, offset } = params
    const sortingParams = getSortingParamsAsLink(params)
    const filtersParams = filters ? parseFiltersParams(filters) : ''

    return `${url}?limit=${limit}&offset=${offset}${sortingParams}${filtersParams}`
  }

  public static getAuditEventsAPI (): string {
    return `${PREFIX_API_V1}/audit-log`
  }

  public static verifyAuditLogAPI (id: string): string {
    return `${PREFIX_API_V1}/audit-log/${id}/verify`
  }

  public static getEnrollmentsProfileAPI (accountId: string): string {
    return `${PREFIX_API_V1}/profile?offset=0&limit=99999999&account_id=${accountId}`
  }

  public static getEnrollment (): string {
    return `${PREFIX_API_V2}/enrollment`
  }

  public static resendEnrollmentAPI (id: string): string {
    return `${PREFIX_API_V2}/enrollment/${id}/resend`
  }

  public static getTemplatesAPI (params: ISort): string {
    const { limit, offset } = params
    return `${PREFIX_API_V1}/template?limit=${limit}&offset=${offset}`
  }

  public static getCertificate (): string {
    return `${PREFIX_API_V1}/certificate`
  }

  public static getCertificatesExportAPI (params: ISort, filters?: IFilters): string {
    const { limit, offset } = params
    const sortingParams = getSortingParamsAsLink(params)
    const filtersParams = filters ? parseFiltersParams(filters) : ''

    return `${PREFIX_API_V1}/certificate/export?limit=${limit}&offset=${offset}${sortingParams}${filtersParams}`
  }

  public static getLimitedProfilesAPI (params: ISort, filters?: IFilters): string {
    const { limit, offset } = params
    const sortingParams = getSortingParamsAsLink(params)
    const filtersParams = filters ? parseFiltersParams(filters) : ''

    return `${PREFIX_API_V1}/profile?limit=${limit}&offset=${offset}${sortingParams}${filtersParams}`
  }

  public static getProfileByIdAPI (id: string): string {
    return `${PREFIX_API_V1}/profile/${id}`
  }

  public static getAccessTokenAPI (): string {
    return `${PREFIX_API_V1}/api-access-token`
  }

  public static getApiTokenByIdAPI (id: string): string {
    return `${PREFIX_API_V1}/api-access-token/${id}/`
  }

  public static getFeatureByAccountAPI (id: string): string {
    return `${PREFIX_API_V1}/features/account/${id}`
  }

  public static enableFeatureForAccountAPI (appId: string, accountId: string): string {
    return `${PREFIX_API_V1}/features/account/${accountId}/${appId}/features`
  }

  public static updateApiTokenByIdAPI (id: string): string {
    return `${PREFIX_API_V1}/api-access-token/${id}/`
  }
  public static getLimitedAccessTokenAPI (params: ISort, filters?: IFilters): string {
    const { limit, offset } = params
    const sortingParams = getSortingParamsAsLink(params)
    const filtersParams = filters ? parseFiltersParams(filters) : ''
    return `${PREFIX_API_V1}/api-access-token?limit=${limit}&offset=${offset}${sortingParams}${filtersParams}`
  }

  public static getAccessTokenEnableAPI (id: string): string {
    return `${PREFIX_API_V1}/api-access-token/${id}/enable`
  }

  public static getProfile (): string {
    return `${PREFIX_API_V1}/profile`
  }

  public static getLicense (): string {
    return `${PREFIX_API_V1}/license/status`
  }

  public static getAMLicenseByApp (appName: string): string {
    return `${appName}api/v1/license/usage`
  }
  public static getAccountLicenseByApp (appName: string,id: string): string {
    return `${appName}api/v1/license/usage/account/${id}`
  }


  public static updateAcountLicenseByApp (appName: string,id: string): string {
    return `${appName}api/v1/license/limit/account/${id}`
  }

  public static updateAccountFeatureFlag (appContext: string, id: string,): string {
      return `${appContext}api/v1/account/${id}/features`
  }


  public static getLicenseAllocationUpdateEvent (acctId: string, appCode: string): string {
    return `${PREFIX_API_V1}/license/limit/${acctId}/${appCode}`
  }

  public static getLicenseAllocationHistory (acctId: string): string {
    return `${PREFIX_API_V1}/license/limit/${acctId}/history`
  }

  public static getLicenseAllocationHistoryDetails (acctId: string): string {
    return `${PREFIX_API_V1}/license/limit/${acctId}/history-details`
  }

  public static createAccountSignInAPI (): string {
    return `${PREFIX_API_V1}/federation/config`
  }

  public static verifyUserNameAPI (userName: string, state: string, locale: string, key: string ): string {
    return `${IMAUTH_API_V1}/login/verify?username=${userName}&state=${state}&locale=${locale}&key=${key}`
  }

  public static redirectAutoLogin (state: string): string {
    return `${IMAUTH_API_V1}/auto-login?state=${state}`
  }


  public static redirectAutoLoginForIdp (state: string): string {
    return `${IMAUTH_API_V1}/auto-login-for-idp-auth?state=${state}`
  }

  public static redirectOauthCallback (state: string): string {
    return `${IMAUTH_API_V1}/callback/oauth?state=${state}`
  }

  public static verifyUserNameExists(userName: string, state?: string, type?: string, id?: string): string {
    let url = `${PREFIX_API_V1}/user/${userName}/find`
    if (state) {
      url = url + `?state=${state}`
    }
    if (type) {
      console.log(type)
      url = url + `?type=${type}`
    }
    if (id) {
      console.log(id)
      url = url + `&userId=${id}`
    }
    return url
  }

  public static resetPasscode (id: string, state: string): string {
    return `${PREFIX_API_V1}/self-service-user/${id}/passcode?state=${state}`
  }

  public static getSelfServiceUser (id: string, state: string): string {
    return `${PREFIX_API_V1}/self-service-user/${id}?state=${state}`
  }

  public static createSelfSignedUser (state: string): string {
    return `${PREFIX_API_V1}/self-service-user?state=${state}`
  }

  public static updateSelfSignedUser (id: string, state: string): string {
    return `${PREFIX_API_V1}/self-service-user/${id}?state=${state}`
  }

  public static createIdpMetadataAPI (federation_provider_id: string): string {
    return `${PREFIX_API_V1}/federation/config/${federation_provider_id}`
  }

  public static getAccountSignInAPI (id: string): string {
    return `${PREFIX_API_V1}/federation/config/${id}`
  }

    public static getSpMetadata (id: string): string {
      return `${PREFIX_API_V1}/federation/config/${id}/sp-metadata`
    }

    public static getClientAuthUrl (): string {
      return `/account/api/v1/clientAuthLoginUrl`
    }

  public static getLimitedCertAuthorityAPI(params: ISort, filters?: IFilters): string {
    const { limit, offset } = params
    const sortingParams = getSortingParamsAsLink(params)
    const filtersParams = filters ? parseFiltersParams(filters) : ''

    return `${CA_API}/ca?limit=${limit}&offset=${offset}${sortingParams}${filtersParams}`
  }

  public static getDomainConfigurationApi (id: string): string {
    return `${PREFIX_API_V1}/account/${id}/sso-email-domains`
  }

  public static validateDomains (id: string, domains: string): string {
      return `${PREFIX_API_V1}/account/${id}/validate-domains?domains=${domains}`
  }

  public static checkInlineOnboardingEligibility (email: string, state: string ): string {
    return `${PREFIX_API_V1}/self-service-user/domain/verify/${email}?state=${state}`
  }

  public static validatePasscode (id: string, state: string): string {
    return `${PREFIX_API_V1}/self-service-user/${id}/validate-passcode?state=${state}`
  }

  public static fetchFeatureFlag (featureName: String): string {
    return `${PREFIX_API_V1}/feature-flag/${featureName}`
  }

  public static fetchCustomLoginSettings (): string {
    return `${PREFIX_API_V1}/login/customization`
  }

  public static fetchNationalities (): string {
    return `${PREFIX_API_V1}/nationalities`
  }

}
