export enum DTATokenName {
  YUBIKEY = 'YUBIKEY',
  ETOKEN = 'ETOKEN',
  MACOS = 'MACOS',
  WINOS = 'WINOS'
}

export const DTAFriendlyName = {
  [DTATokenName.YUBIKEY]: 'Yubikey',
  [DTATokenName.ETOKEN]: 'eToken'
}

export enum DTAKeystoreType {
  Platform = 'Platform',
  HWToken = 'HWToken',
  SWToken = 'SWToken'
}

export enum DTAKeyStoreLocation {
  OSKeystore = 'OSKS',
  DigicertKeystore = 'DIGICERT_SOFTWARE',
  HardwareToken = 'HWTK'
}

export enum DTAHardwareTokenOptionsType {
  ETOKEN = 'ETOKEN',
  YUBIKEY = 'YUBIKEY',
  ANY = 'ANY'
}

export const DTAKeystoreLocationToKeystoreType = {
  [DTAKeyStoreLocation.OSKeystore]: DTAKeystoreType.Platform,
  [DTAKeyStoreLocation.DigicertKeystore]: DTAKeystoreType.SWToken,
  [DTAKeyStoreLocation.HardwareToken]: DTAKeystoreType.HWToken
}

export enum DTAYubikeySlot {
  Authentication = 'Authentication',
  DigitalSignature = 'DigitalSignature',
  KeyManagement = 'KeyManagement',
  CardAuthentication = 'CardAuthentication'
}

//time given to user to open DTA client wia prompt or manually
export const DTACreateTimeout = 15000

//delay before retry attempt
export const DTAInitializeRetryTimeout = 500

//default dev license
export const devDefaultlicense =
  'eyJhbGciOiJFUzM4NCJ9.eyJzdWIiOiIwNjIzYzhmY2Q4Zjk0NWM5YmY0ZmNjZjcwYTNkNzEzNyIsImlhdCI6MTY4Mjk3MzAwNywiZXhwIjoxNzYxNzQ4Njk0LCJpc3MiOiJodHRwczovL3RydXN0YXNzaXN0YW50LmRpZ2ljZXJ0LmNvbSIsImlkIjoiNTFlOTIxN2EtYjFmMS00OGUyLTk2NTMtZjIyYmY5YWQ1NTczIiwidmVyc2lvbiI6IjEuMC4wIiwib3JnYW5pemF0aW9uIjoiRGlnaUNlcnQgRGV2IiwiYWxsb3dlZE9yaWdpbnMiOlsiZGNvbmUuY2x1c3Rlci5sb2NhbCIsImxvY2FsaG9zdCIsIjEyNy4wLjAuMSIsIjo6MSIsIiouYmJ0ZXN0Lm5ldCIsIiouc3ltY2xhYi5uZXQiLCIqLnNib2xhYi5ibHUuZGlnaWNlcnQuY29tIl19.nMsc43-KEjezWsHgem2Himfi3E-Kz5YeyajYTNvxFMLhgEOwLkzm15wweRh4-c_oa33qJbFQi1_raxzBl27SoHeYfFJDiT9JFJtLBvdpHht568oJI6f-gcoxk8whGqTB'
//default prod license
export const prodDefaultLicense =
  'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ2ZXJzaW9uIjoiMS4wLjAiLCJjb21wYW55IjoiRGlnaUNlcnQsIEluYy4iLCJhbGxvd2VkT3JpZ2lucyI6WyIqLnN5bWF1dGguY29tIiwiKi5iYnRlc3QubmV0IiwiKi5kaWdpY2VydC5jb20iLCIqLnF1b3ZhZGlzZ2xvYmFsLmNvbSIsIioub25lLmRpZ2ljZXJ0LmNvLmpwIiwiZGNvbmUuY2x1c3Rlci5sb2NhbCIsImRjb25lLndvcmsiLCIqLmJ0bXBraS5jb20iLCIqLnNtYXJ0ZGNjLmNvLnVrIiwiKi5kY2NpbnRlcmZhY2UuY28udWsiLCIqLnZpbm8uYnQuY28udWsiLCIqLmJ0LmNvbSIsIiouYnQucHNuLXotc2VydmljZS5uZXQiLCIqLmFncmliYW5rLmNvbS52biJdLCJpZCI6IjkxYTQwMWUwLTg2NGUtNDViZC04MjEzLWJlOGZmMDQ3MDg2NCIsImlhdCI6MTY3NTM4MjQwMDAwMCwiaXNzIjoiaHR0cHM6Ly90cnVzdGFzc2lzdGFudC5kaWdpY2VydC5jb20iLCJzdWIiOiJMaWNlbnNlIENvZGUgb2YgRGlnaUNlcnQgVHJ1c3QgQXNzaXN0YW50IEphdmEgU0RLIiwiZXhwIjoxNzcwMDc2Nzk5MDAwfQ.ReFh2NlGmIw2dnqwYUi9RQRpaNNed_DJSFkVPdoLM58MSCtX2Sb9IksxHK253EDyzVwG2nKgXIBmgxbBQuczygtTSSQeMMKoXqfb0pMHWjWYp208qL5oQsvlTCpJjTfezQb4LkanZBHLv7JGV3uNXcQKWRNB6Hh6jIvPmpbEj2f5jgGaEL5essq2tIuZwiXQXjF7bsR1TxtAbkSki_nAthZs8RIRpgdqdYqvV0raz87gGe2SDJ9mCNbhq3PxMcnwUUIVQ_d01Uwqxhjsxv0xSKJbKdsvzGNRaxIerVm04-NXmf-P1ht5CShkDeSWyR0lnNUE7btVAdv9dyGq2utJWQ'

export enum Environment {
  Production = 'production'
}

export enum DTAVerisons {
  supportsDynamicLicense = '1.1.1',
  supportsMSCA = '1.1.2',
  supportsRSAPSS = '1.1.3',
  supportsPostProcessingForWins = '1.1.4'
}


