export const DEFAULT_LIMIT = 20

export const initialTableReducer = {
  items: [],
  limit: DEFAULT_LIMIT,
  offset: 0,
  total: 0
}

export const TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss'

export enum CertificateStatuses {
  ISSUED = 'issued',
  REVOKED = 'revoked',
  EXPIRED = 'expired'
}

export enum FeatureFlags {
  ENABLE_ACTIVATE_STEP = 'enableActivateStepForDsmSigner'
}

export const DIGIROOT_ID = '00000000-0000-0000-0000-000000000000'


export enum AccountFeatures {
  STM_TRIAL_ACCOUNT = 'ftr_sm_trial_account',
  STM_KEYLOCKER_ACCOUNT ='ftr_sm_keylocker_account',
  STM_PUBLIC_ISSUANCE = 'ftr_sm_public_issuance'
}

export const SystemAccount = 'System Account'

export enum STMApplicationName {
  SECURE_SOFTWARE = 'Software Trust',
  KEYLOCKER = 'KeyLocker'
}

export const DEVICE_TRUST_MANAGER = 'Device Trust Manager'
