import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react'
import styles from './Login.module.scss'
import { Translate, withLocalize } from 'react-localize-redux'
import { connect } from 'react-redux'
import { IAppState } from 'core/store/store'
import { DCONE_LOCALE_COOKIE_NAME, USER_NAME_REGEXP } from 'core/utils/constants'
import {
  getDecodedState,
  getValueFromState,
  isOauthFlow,
  showCreateOneLink,
  isValidAccountIdPresent,
  translate
} from 'shared/helpers/utils'
import {
  Button,
  ButtonType,
  FormFieldSize,
  IncontextBanner,
  Input,
  PageLevelBanner,
  PageLevelBannerType,
  Spinner
} from '@digicert/dcone-common-ui'
import { fetchCustomLoginSettings, verify } from './Login.actions'
import { LoginProps } from './Login.types'
import { BasePathConst } from '../../configs/Path.configs'
import cn from 'classnames';
import Cookies from 'js-cookie'
import history from 'core/history/history'
import { Dropdown, Menu } from 'antd';
import { fetchLocales } from 'core/actions/Settings.actions'
import { ILocale } from '../../reducers/Reducers.interfaces'
import { ReactComponent as DcLogo } from '../../assets/images/DC_logo_white.svg';
import { ICustomSettings } from 'reducers/Reducers.interfaces'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleUp } from '@fortawesome/pro-regular-svg-icons';
import { faGlobeAmericas } from '@fortawesome/pro-light-svg-icons';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';

const Login = (props: LoginProps) : ReactElement => {
  const currentYear = new Date().getFullYear()
  const [userName, setUserName] = useState('')
  const [spin, setSpin] = useState(false)
  const [errorState, setErrorState] = useState(false)
  const [friendlyName, setFriendlyName] = useState('')
  const urlSearchParams = new URLSearchParams(window.location.search);
  const [state, setState] = useState('')
  const [isShowCreateOneLink, setIsShowCreateOneLink] = useState(false)
  const [validAccountTokenPresent, setValidAccountTokenPresent] = useState(false)
  const [locales, setLocales] = useState<ILocale[]>([])
  const [pageLanguage, setPageLanguage] = useState<string | undefined>()
  const [selectedLocale, setSelectedLocale] = useState<string | undefined>()
  const [error, setError] = useState('')
  const [customSettings, setCustomSettings] = useState<ICustomSettings>()
  const [isDtaOauthRequest, setIsDtaOauthRequest] = useState(false)

  const menu = (
    <Menu>
      {locales.map((item, index) => {
        return (
          <Menu.Item
            key={index}
            onClick={() => onLocaleChange(item.name, item.id)}
          >
            {item.name}
          </Menu.Item>
        );
      })}
    </Menu>
  );

 useEffect(() => {
    const stateVal = urlSearchParams.get('state')
    if (stateVal != null) {
      setState(stateVal)
      const isOauthTrue = isOauthFlow(stateVal)
      setIsShowCreateOneLink(showCreateOneLink(stateVal))
      const decodedState = getDecodedState(stateVal)
      const stateDecoded = atob(stateVal)
      const isDtaOauthRequestFromState = getValueFromState("isDtaOauthRequest=", stateDecoded) 
      setIsDtaOauthRequest(isDtaOauthRequestFromState ? isDtaOauthRequestFromState === 'true' : false)
      const loginHint = getValueFromState("login_hint=", stateDecoded)
      if(loginHint !== "" ) {
         setUserName(loginHint)
      }

      if (decodedState.includes('error=username_not_found')) {
         setErrorState(true)
         setFriendlyName('')
      }
      if (isOauthTrue) {

          if (Cookies.get(DCONE_LOCALE_COOKIE_NAME)) {
            props.setActiveLanguage(Cookies.get(DCONE_LOCALE_COOKIE_NAME).split('_')[0])
          }

          if (isValidAccountIdPresent(stateVal)) {
            setValidAccountTokenPresent(true)
          }

          const fn = getValueFromState("friendly_name=", stateDecoded)
          if(fn !== "" ) {
            setFriendlyName(fn)
          }




      }
    } else {
      history.push(`${BasePathConst}`)
    }
  }, [])

  useEffect(() => {
    props.fetchLocales().then((response) => {
      setLocales(response)
    })
  }, [fetchLocales])

  useEffect(() => {
    props.fetchCustomLoginSettings().then((response) => {
      setCustomSettings(response)
    })
  }, [fetchCustomLoginSettings])

  useEffect(() => {
    document.body.classList.add("pub-login")
  }, [])

  const onLocaleChange = (language, countryCode) => {
    setPageLanguage(language)
    Cookies.set(DCONE_LOCALE_COOKIE_NAME, countryCode)
    if (countryCode != 'zh_CN' && countryCode != 'pt_BR' && countryCode != 'zh_TW') {
      setSelectedLocale(language)
    } else {
      setSelectedLocale(countryCode.replace('_', '-'))
    }
    window.location.reload()
  }


  useEffect(() => {
    setPageLanguage('English')
    setSelectedLocale('en')
    const locale = Cookies.get(DCONE_LOCALE_COOKIE_NAME)
    if (locale?.includes('-')) {
      locale?.replace('-', '_')
    }
    const tmpPageLang = locales.filter((item) => item.id === locale)[0]
    if(tmpPageLang && tmpPageLang.name) {
      setPageLanguage(tmpPageLang.name)
    }
    if (locale) {
      const language = locale.split('_')[0]
      props.setActiveLanguage(language)

      if (locale != 'zh_CN' && locale != 'pt_BR' && locale != 'zh_TW') {
        setSelectedLocale(language)
      } else {
        setSelectedLocale(locale.replace('_', '-'))
      }

    }
  }, [locales])

  const onUserNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setUserName(event.target.value)
    setError('')
  }

  const onClick = (e) => {
    e.preventDefault()
    setError('')
    if (userName === '') {
        setError("common.form.validation.requiredUserName")
        return
    }
    setSpin(true)
    props.verify(userName, urlSearchParams.get('state'), selectedLocale)
  }

  return (
    <>
    {customSettings && 
      <div className={styles.login}>
        {isShowCreateOneLink
          ? (<DcLogo className={styles.dcLogo}/>)
          : <img className={styles.dcOneLogo} src={customSettings?.logo}/>
        }

        <div className={styles.bannerContainer}>
          {friendlyName !== '' && !isDtaOauthRequest &&
            <IncontextBanner
              bannerType="note"
              description={ <Translate id={'common.login.info'} data={{ name: friendlyName}}/>}
            />
          }
        </div>

        <form className={cn(styles.logInForm, errorState && styles.invalid)} onSubmit={onClick}>
          {spin &&
            <div className={styles.spinner}>
              <Spinner />
            </div>
          }
          {!spin &&
            <>
              <p className={styles.heading}>
                {isShowCreateOneLink
                  ? (<Translate id='common.signIn'/>)
                  : (<Translate id='common.login.title'/>)
                }
              </p>

              <div className={styles.groupName}>
                {errorState &&
                  <PageLevelBanner
                    bannerType={PageLevelBannerType.ERROR}
                    description= {translate('common.login.loginUsernameNotFoundError') as string}
                  />
                }
                <div className={styles.group}>
                  <Input
                    autoFocus
                    size={FormFieldSize.L}
                    label={<Translate id='common.form.username' />}
                    value={userName}
                    onChange={onUserNameChange}
                    pattern={USER_NAME_REGEXP}
                    errors={{
                      patternMismatch: translate('common.form.validation.invalidUserName')
                    }}
                    autoComplete="username"
                    id="username"
                    name="username"
                    className={cn(error && styles.invalidUserName)}
                  />
                </div>
                {error && (
                   <p className={styles.error}>
                      <FontAwesomeIcon
                         icon={faExclamationCircle}
                         className={styles['error-icon']}
                      />
                      <Translate id={error}/>
                   </p>
                )}
                <div className={styles.actions}>
                  <Button buttonType={ButtonType.PRIMARY} onClick={onClick}>
                    <Translate id='common.form.buttons.nextBtn'/>
                  </Button>
                </div>
              </div>

              {isShowCreateOneLink &&
                <p className={styles.createAccountMessaging}>
                  <Translate id='common.dontHaveAnAccount'/>
                    &nbsp;
                    <a rel="noreferrer" href= {`${BasePathConst}/create-user-account?state=${state}`} target="_blank">
                      <Translate id='common.createOne'/>
                    </a>
                </p>
               }
          
            </>
          
        }
        </form>

        <div className={styles.termsOfUse}>
          <div className={styles.languageOption}>
            <div>
              <FontAwesomeIcon icon={faGlobeAmericas} className={styles.languageIcon}/> <span><Translate id='common.language'/>:</span>
            </div>
            <Dropdown className={styles.languageOptions} overlay={menu} placement='topRight' trigger={['click']}>
              <button type='button' aria-label={translate('common.selectLanguage') as string}>
                {pageLanguage}{' '}
                <FontAwesomeIcon icon={faAngleUp}/>
              </button>
            </Dropdown>
          </div>
          <div className={styles.links}>
            {customSettings?.urls.termsOfUse && <a className={styles.termsOfUseLinks} href={customSettings?.urls.termsOfUse}><Translate id='common.agreement.terms' /></a>}
            {customSettings?.urls.privacyPolicy && <a className={styles.termsOfUseLinks} href={customSettings?.urls.privacyPolicy}><Translate id='common.agreement.policy' /></a>}
            {customSettings?.urls.legalRepository && <a className={styles.termsOfUseLinks} href={customSettings?.urls.legalRepository}><Translate id='common.agreement.legal' /></a>}
            {customSettings?.urls.webtrustAudit && <a className={styles.termsOfUseLinks} href={customSettings?.urls.webtrustAudit}><Translate id='common.agreement.audit' /></a>}
            {customSettings?.urls.support && <a className={styles.termsOfUseLinks} href={customSettings?.urls.support}><Translate id='common.agreement.support' /></a>}
            {customSettings?.urls.contactUs && <a className={styles.termsOfUseLinks} href={customSettings?.urls.contactUs}><Translate id='common.agreement.contact' /></a>}
          </div>
          <div className={styles.copyRights}>
            &copy;{currentYear} {customSettings?.copyright}
          </div>
        </div>
      </div>
}
</>
    
)
}
export default connect(
  (state: IAppState) => {
    return {
    }
  },
  {
    verify, fetchLocales, fetchCustomLoginSettings
  })(withLocalize(Login))