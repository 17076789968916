import React, { ReactElement } from 'react'
import { translate } from '../../../helpers/Translation.helpers'
import { PageLevelBanner, PageLevelBannerType } from '@digicert/dcone-common-ui'

import clientToolsStyles from 'secure-pages/resources/client-tools/ClientTools.module.scss'

export const DCKSInitialize = (): ReactElement => {
  return (
    <div>
      <PageLevelBanner
        bannerType={PageLevelBannerType.INFO}
        title={<b>{translate('dta.error.DCKSUninitialized.title')}</b>}
        description={
          <div>
            <span className='pre-line'>
              <br />
              {translate('dta.error.DCKSUninitialized.description')}
            </span>
            <span className={clientToolsStyles.detailsTextBold}>{translate('dta.error.DCKSUninitialized.initializeSteps')}</span>
          </div>
        }
      />
      {/* DOCORE-5752: removed DCKS initialization from AM */}
    </div>
  )
}