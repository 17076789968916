import { ThunkDispatch } from 'redux-thunk'
import HTTPClient from 'core/services/HTTPClient'
import { showNotification } from 'core/actions/Notifications.actions'
import { IFilters, ISort } from 'core/actions/Actions.interfaces'
import { defaultListParams, RouterConfig } from 'core/utils/constants'
import history from 'core/history/history'
import { actionCoreApi } from 'core/utils/api'
import { ActionType, dispatcherActionType } from 'core/utils/interfaces'
import ActionsList from 'core/actions/Actions.list'
import { IAppState } from 'core/store/store'
import { actionErrorNotifier } from 'core/utils/helpers'
import HTTPUrlHelper from 'shared/helpers/HTTPUrlHelper'
import { generateRandomId, translate } from 'shared/helpers/utils'
import { mapSwitchersValuesToId } from 'shared/components/switchers-template/switchers-template.constants'
import { mapSeatTypeToServer } from 'shared/components/seat-allocations/seat-allocations.helpers'
import { IAction, LicenseFormData } from './Tenants.types'
import { IAccessScope, NotificationTypes } from 'reducers/Reducers.interfaces'
import { ITenantsFormValues } from './tenants-form/TenantsForm.types'
import TenantConstants from './Tenants.constants'

export const fetchTenants = (params: ISort = defaultListParams, filters?: IFilters): ActionType =>
  actionErrorNotifier(actionCoreApi(
    TenantConstants.FetchTenants,
    HTTPUrlHelper.getFilteredAPI(HTTPUrlHelper.getTenantsAPI(), params, filters)
  ))

export const unlinkAccountApi = (accountId: string): ActionType => 
    actionCoreApi(
      TenantConstants.UpdateMfa,
      HTTPUrlHelper.getUnlinkAccountApi(accountId),
      'POST',
    )

  export const fetchExpiringAccounts = (): ActionType =>
  actionErrorNotifier(actionCoreApi(
    TenantConstants.fetchExpiringAccounts,
    HTTPUrlHelper.getExpiringTenantsAPI()
  ))  

export const fetchTenantById = (id: string): ActionType =>
actionErrorNotifier(actionCoreApi(
  TenantConstants.FetchTenantRequest,
  HTTPUrlHelper.getTenantByIdAPI(id)
))

export function createTenantWithNewAccount (formData: ITenantsFormValues, accessScope: IAccessScope): (dispatch: ThunkDispatch<IAppState, {}, IAction>) => Promise<void> {
  return async (dispatch: ThunkDispatch<IAppState, {}, IAction>): Promise<void> => {
    const errorNotification = (error): void => {
      dispatch({ type: TenantConstants.CreateTenantFailure })
      dispatch(showNotification({
        id: generateRandomId(),
        title: `notifications.exceptions.${error.status}`,
        message: error.message,
        translateMessage: false,
        type: NotificationTypes.error
      }))
    }
    dispatch({ type: TenantConstants.CreateTenantRequest })

    const SUCCESS_STATUS = 201
    const tenantURL = HTTPUrlHelper.getTenantsAPI()
    const accountURL = HTTPUrlHelper.getAccountsAPI()
    const { data, status } = await HTTPClient.POST(tenantURL, formData)

    if (status === SUCCESS_STATUS && accessScope === IAccessScope.system) {
      const [responseTemplate, responseSeatPools] = await Promise.all(
        [
          HTTPClient.PUT(`${HTTPUrlHelper.getTenantByIdAPI(data.id)}/template`, mapSwitchersValuesToId(formData.templates)),
          HTTPClient.PUT(`${HTTPUrlHelper.getTenantByIdAPI(data.id)}/licensed-seats`, mapSeatTypeToServer(formData.seatTypes))
        ])
      if (responseTemplate.status !== 200 && responseSeatPools.status !== 200) {
        errorNotification(responseTemplate.data.error || responseSeatPools.data.error)
        return
      }
      dispatch({ type: TenantConstants.CreateTenantSuccess })

      // Create account in scope of created tenant
      const payload = { name: data.name, tenant: { id: data.id } }
      const accountResponse = await HTTPClient.POST(accountURL, payload)

      if (accountResponse.status === SUCCESS_STATUS) {
        dispatch(showNotification({
          id: generateRandomId(),
          message: 'notifications.accounts.createAccountSuccess',
          type: NotificationTypes.success
        }))
      } else {
        dispatch(showNotification({
          id: generateRandomId(),
          title: 'notifications.accounts.createAccountTitle',
          message: `notifications.accounts.${data.error.status}`,
          type: NotificationTypes.error
        }))
      }
    }

    if (data.error) {
      errorNotification(data.error)
    }
  }
}

export function editTenant (id: string, formData, accessScope): (dispatch: ThunkDispatch<IAppState, {}, IAction>) => Promise<void> {
  return async (dispatch: ThunkDispatch<IAppState, {}, IAction>): Promise<void> => {
    dispatch({ type: TenantConstants.EditTenantRequest })
    const errorNotifier = (error): void => {
      dispatch({ type: TenantConstants.EditTenantFailure })
      dispatch(showNotification({
        id: generateRandomId(),
        title: `notifications.exceptions.${error.status}`,
        message: error.message,
        type: NotificationTypes.error,
        translateMessage: false
      }))
    }
    const URL: string = HTTPUrlHelper.getTenantByIdAPI(id)
    const { data, status } = await HTTPClient.PUT(URL, formData)
    if (data && status === 200 && accessScope === IAccessScope.system) {
        dispatch({ type: TenantConstants.EditTenantSuccess })
        dispatch(showNotification({
          id: generateRandomId(),
          message: 'notifications.accounts.editAccountSuccess',
          type: NotificationTypes.success
        }))
        history.push(RouterConfig.accounts.table)
      } else {
        errorNotifier(data.error)
      }
  }
}


export const enableMfa = (id: string, formData, msg: string): ActionType =>
  actionErrorNotifier(
    actionCoreApi(
      TenantConstants.UpdateMfa,
      HTTPUrlHelper.getEnableMfaAPI(id),
      'PUT',
      formData
    ), true,
    {
      title: '',
      message: msg
    }
  )


export function editStandardSignIn (id: string, formData): (dispatch: ThunkDispatch<IAppState, {}, IAction>) => Promise<void> {
  return async (dispatch: ThunkDispatch<IAppState, {}, IAction>): Promise<void> => {
    dispatch({ type: TenantConstants.EditStdSigninRequest })
    const errorNotifier = (error): void => {
      dispatch({ type: TenantConstants.EditStdSigninFailure })
      dispatch(showNotification({
        id: generateRandomId(),
        title: `notifications.exceptions.${error.status}`,
        message: error.message,
        type: NotificationTypes.error,
        translateMessage: false
      }))
    }
    const URL: string = HTTPUrlHelper.getEditSigningAPI(id)
    const { data, status } = await HTTPClient.PUT(URL, formData)
    if (data && status === 200) {
        dispatch({ type: TenantConstants.EditStdSigninSuccess })
        dispatch(showNotification({
          id: generateRandomId(),
          message: 'notifications.accounts.editAccountSuccess',
          type: NotificationTypes.success
        }))
        history.push(RouterConfig.accounts.table)
      } else {
        errorNotifier(data.error)
      }
  }
}

export const deleteTenants = (ids: string[]): ActionType =>
  actionErrorNotifier(
    actionCoreApi(
      TenantConstants.DeleteTenant,
      ids.map((id: string): string => HTTPUrlHelper.getTenantByIdAPI(id)),
      'DELETE'
    ), true,
    {
      title: '',
      message: 'notifications.accounts.deleteAccountSuccess'
    }
  )

export const fetchAssignedTemplatesToTenant = (id: string): ActionType =>
  actionErrorNotifier(actionCoreApi(
    ActionsList.FetchAssignedTemplates,
    `${HTTPUrlHelper.getTenantByIdAPI(id)}/template`
  ))
  
  export function updateLicenseByApp (formData: LicenseFormData, name: string,id: string, licenseEventPayload: any, app_code: string): (dispatch: ThunkDispatch<IAppState, {}, IAction>) => Promise<void> {
    return async (dispatch: ThunkDispatch<IAppState, {}, IAction>): Promise<void> => {
      window.licenseData = ''
      const url = HTTPUrlHelper.updateAcountLicenseByApp(name,id)
      const { data, status } = await HTTPClient.PUT(url, formData)
      let errorMessage = 'notifications.errors.genericError'
      if (status === 204 || status === 200) {
        fetch(HTTPUrlHelper.getLicenseAllocationUpdateEvent(id, app_code), 
                {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json'
                  },
                  body: JSON.stringify(licenseEventPayload)}).then(res => {
                    if(res.ok) {
                      dispatch(showNotification({
                        id: generateRandomId(),
                        title: '',
                        message: 'notifications.accounts.licenseUpdated',
                        type: NotificationTypes.success
                      }))
                    }
                  })
        setTimeout(() => {
          fetch(HTTPUrlHelper.getAccountLicenseByApp(name, id))
          .then(async (response: any) => {
            if (response.ok) {
              window.licenseData = await response.json()
            }
          })
        }, 500)
      }
      else {
        if (data.errors[0].code && translate(`notifications.exceptions.${data.errors[0].code}`) !== data.errors[0].code) {
          errorMessage = `notifications.exceptions.${data.errors[0].code}`
        }
        dispatch(showNotification({
          id: generateRandomId(),
          title: '',
          message: errorMessage,
          type: NotificationTypes.error
        }))
      }
    }
  }

  export const createLicenseAllocationUpdateEvent = (formData, accountId: string, appCode: string): dispatcherActionType => actionErrorNotifier(
    actionCoreApi(
      ActionsList.CreateLicenseAllocationUpdateEvent,
      HTTPUrlHelper.getLicenseAllocationUpdateEvent(accountId, appCode),
      'POST',
      formData
    ), false
  )

  export const getLicenseLimitEvents = (params: ISort = defaultListParams, accountId: string, timezone: string): ActionType =>
  actionCoreApi(
    ActionsList.GetLicenseLimitEvents,
    HTTPUrlHelper.getFilteredAPI(HTTPUrlHelper.getLicenseAllocationHistory(accountId), params) + `&timezone=${timezone}`
  )

  export const getLicenseLimitDetails = (params: ISort = defaultListParams, accountId: string, timezone: string): ActionType =>
  actionCoreApi(
    ActionsList.GetLicenseLimitEvents,
    HTTPUrlHelper.getFilteredAPI(HTTPUrlHelper.getLicenseAllocationHistoryDetails(accountId), params) + `&timezone=${timezone}`
  )

export const fetchConfiguredDomains = (id: string): ActionType =>
    actionErrorNotifier(actionCoreApi(
        ActionsList.FetchConfiguredDomains,
        HTTPUrlHelper.getDomainConfigurationApi(id),
    ))


export function addDomainsToAccount (id: string,  domains: any): (dispatch: ThunkDispatch<IAppState, {}, IAction>) => Promise<void> {
  return async (dispatch: ThunkDispatch<IAppState, {}, IAction>): Promise<void> => {
    const URL: string = HTTPUrlHelper.getDomainConfigurationApi(id)
    const { data, status } = await HTTPClient.POST(URL, domains)
    if (status === 201) {
         dispatch(showNotification({
                  id: generateRandomId(),
                  message: 'optionalSignInSettings.notifications.updateSuccess',
                  type: NotificationTypes.success
                }))
      } else {
         return data.error.message
      }
  }
}


export function updateDomains (id: string,  domains: any): (dispatch: ThunkDispatch<IAppState, {}, IAction>) => Promise<void> {
  return async (dispatch: ThunkDispatch<IAppState, {}, IAction>): Promise<void> => {
    const URL: string = HTTPUrlHelper.getDomainConfigurationApi(id)
    const { data, status } = await HTTPClient.PUT(URL, domains)
    if (status === 200) {
         dispatch(showNotification({
                  id: generateRandomId(),
                  message: 'optionalSignInSettings.notifications.updateSuccess',
                  type: NotificationTypes.success
                }))
      } else {
        return data.error.message
      }
  }
}

export function validateDomains (id: string,  domains: any): (dispatch: ThunkDispatch<IAppState, {}, IAction>) => Promise<void> {
  return async (dispatch: ThunkDispatch<IAppState, {}, IAction>): Promise<void> => {
    const URL: string = HTTPUrlHelper.validateDomains(id, domains)
    const { data, status } = await HTTPClient.GET(URL)
    if (status === 400) {
        return data.message
      }
  }
}

export const addOauthClient = (): ActionType =>
  actionErrorNotifier(actionCoreApi(
    ActionsList.AddOauthClient,
    [HTTPUrlHelper.getAccountOuthClientsAPI()],
    'POST',
    {},
  ))