import { IAction } from "core/actions/Actions.interfaces";
import ActionsList from "core/actions/Actions.list";
import HTTPClient from "core/services/HTTPClient";
import { IAppState } from "core/store/store";
import { actionCoreApi } from "core/utils/api";
import { RouterConfig } from "core/utils/constants";
import { ActionType } from "core/utils/interfaces";
import { ThunkDispatch } from "redux-thunk";
import HTTPUrlHelper from "shared/helpers/HTTPUrlHelper";
import { AutoLoginPayload, CreateSelfServiceUserPayload } from "./SelfServiceUser.types";
import { isOauthFlow } from 'shared/helpers/utils'

export const createSelfServiceUser = (payload: CreateSelfServiceUserPayload, state: string): ActionType =>
  actionCoreApi(
    ActionsList.CreateSelfSignedUser,
    HTTPUrlHelper.createSelfSignedUser(state),
    'POST',
    payload
  )

export const checkIfUsernameExists = (username: string, state?: string, type?: string, id?: string): ActionType =>
  actionCoreApi(
    ActionsList.UserNameExists,
    HTTPUrlHelper.verifyUserNameExists(username, state, type, id),
    'GET'
  )



export const getSelfServiceUser = (id: string, state: string): ActionType =>
  actionCoreApi(
    ActionsList.GetSelfServiceUser,
    HTTPUrlHelper.getSelfServiceUser(id, state),
    'GET'
  )
export const resetPasscode = (id: string, state: string): ActionType =>
  actionCoreApi(
    ActionsList.ResetPasscode,
    HTTPUrlHelper.resetPasscode(id, state),
    'PUT'
  )

export function updateSelfServiceUser(payload: CreateSelfServiceUserPayload, id: string, state: string): (dispatch: ThunkDispatch<IAppState, {}, IAction>) => Promise<void> {
  return async (dispatch: ThunkDispatch<IAppState, {}, IAction>): Promise<void> => {
    const url = HTTPUrlHelper.updateSelfSignedUser(id, state)
    const { data, status } = await HTTPClient.PUT(url, payload)
    if (status === 200 && data.status === 'CREATED') {
      if (payload.user_authenticated_in_idp) {
        dispatch(redirectAutoLoginForIdp(state)).then((res) => {
          window.location.href = res
        })

      } else {
        const data1: AutoLoginPayload = {
          user_name: payload.user_name,
          password: payload.password ? payload.password : '',
          user_authenticated_in_idp: payload.user_authenticated_in_idp,
          self_service_request_id: id
        }
        const url = HTTPUrlHelper.redirectAutoLogin(state)
        const { data, status } = await HTTPClient.POST(url, data1)
        const response = data
        if (response && response.return_url) {
          window.location.href = response.return_url
        }
      }
    }
    else {
      if (status === 400) {
        if (payload.dta_request) {
          window.valid_sso_response = false
          window.error_message = data.error.message

        } else {
          window.otp_valid = false
        }
      }
    }
  }
}

export const redirectAutoLogin = (payload: AutoLoginPayload, state: string): ActionType =>
  actionCoreApi(
    ActionsList.RedirectAutoLogin,
    HTTPUrlHelper.redirectAutoLogin(state),
    'POST',
    payload
  )

export const redirectAutoLoginForIdp = (state: string): ActionType =>
  actionCoreApi(
    ActionsList.RedirectAutoLogin,
    HTTPUrlHelper.redirectAutoLoginForIdp(state),
    'GET'
  )


export const redirectOauthCallback = (state: string): ActionType =>
  actionCoreApi(
    ActionsList.RedirectOauthCallback,
    HTTPUrlHelper.redirectOauthCallback(state),
    'GET'
  )

export function checkInlineOnboardingEligibility(email: string, state: string): (dispatch: ThunkDispatch<IAppState, {}, IAction>) => Promise<void> {
  return async (dispatch: ThunkDispatch<IAppState, {}, IAction>): Promise<void> => {
    const url = HTTPUrlHelper.checkInlineOnboardingEligibility(email, state)
    const { data, status } = await HTTPClient.GET(url)
    if (status === 200) {
      return data;
    }
    else {
      if (status === 400) {
        window.error_message = data.error.message
      }
    }
  }
}



export function validatePasscode(payload: CreateSelfServiceUserPayload, id: string, state: string): (dispatch: ThunkDispatch<IAppState, {}, IAction>) => Promise<void> {
  return async (dispatch: ThunkDispatch<IAppState, {}, IAction>): Promise<void> => {
    const url = HTTPUrlHelper.validatePasscode(id, state)
    const { data, status } = await HTTPClient.POST(url, payload)
    if (status === 200) {
      window.otp_valid = true
    }
    else {
      if (status === 400) {
        window.otp_valid = false
      }
    }
  }
}



export const fetchFeatureFlag = (featureName: string): ActionType =>
  actionCoreApi(
    ActionsList.FetchFeatureFlag,
    HTTPUrlHelper.fetchFeatureFlag(featureName),
    'GET'
  )

export const fetchNationalities = (): ActionType =>
  actionCoreApi(
    ActionsList.FetchNationalities,
    HTTPUrlHelper.fetchNationalities(),
    'GET'
  )