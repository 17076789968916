import React, { Component, ReactNode } from 'react'
import { IAppState } from 'core/store/store'
import { createAccountSignIn, createIdpMetadata, getAccountSignIn, getSpMetadata } from './AccountSignIn.actions'
import {
  CreateAccountSignInOwnProps,
  CreateAccountSignInProps,
  CreateAccountSignInStateProps,
  IAccountSignInState
} from './AccountSignIn.types'
import { connect } from 'react-redux'
import { Translate } from 'react-localize-redux'
import styles from './AccountSignIn.module.scss'
import { Spin } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import fileDownload from 'js-file-download'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import history from 'core/history/history'
import { parseCertificate } from './CertificateParser'
import { RouterConfig } from 'core/utils/constants'
import { fetchTenantById } from '../Tenants.actions'
import { Button, ButtonType, IncontextBanner } from '@digicert/dcone-common-ui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/pro-light-svg-icons'

class CreateAccountSignIn extends Component<CreateAccountSignInProps, IAccountSignInState> {
  state: IAccountSignInState = {
    idp_metadata: '',
    idp_certificates: [],
    showDetails: false,
    showCertDetails: false,
    showError: false,
    idp_url: '',
    thumbprints: [],
    showDuplicateError: false,
    disableSigleSIgnOn: false,
    showCertError: false,
    showCertExpiredError: false,
    isEditMode: false,
    standardSignInstatus: '',
    status: '',
    tenant: this.props.tenant,
    loading: true,
    federationProviderId: ''
  }

  public async componentDidMount (): Promise<void> {
    const { match } = this.props
    const payload = {
      auth_type: 'SAML 2.0',
      account_id: match.params.id
    }
    this.props.fetchTenantById(match.params.id).then((tenant: any) => {
      if (tenant) {
        this.setState({ tenant: tenant })
      }
    }).finally(() => {
      if (this.state.tenant) {
        const federationProvider = this.state.tenant.auth_config ? this.state.tenant.auth_config.find(item => item.type === 'SAML 2.0') : undefined
        const federationId = federationProvider ? federationProvider.id : ''
        const standardSignInMethod = this.state.tenant.sign_in_methods.find(item => item.signInMethod === 'standard')
        const standardSignInstatus = standardSignInMethod ? standardSignInMethod.status : 'disabled'
        const ssoMethod = this.state.tenant.sign_in_methods.find(item => item.signInMethod === 'singleSignOnSaml')
        const ssoStatus = ssoMethod ? ssoMethod.status : standardSignInstatus
        this.setState({ standardSignInstatus: standardSignInstatus })
        this.setState({ status: ssoStatus })
        this.createOrGetAccountSignIn(payload, federationId).then((response: any) => {
          this.setState({ federationProviderId: response.id })
          if (this.props.ferderation.identity_provider_config && this.props.ferderation.identity_provider_config.certificates.length > 0) {
            this.setState({ isEditMode: true })
            this.setState({ idp_certificates: this.props.ferderation.identity_provider_config.certificates })
            this.setState({ idp_metadata: this.props.ferderation.identity_provider_config.metadata.metadata })
            this.setState({ idp_url: this.props.ferderation.identity_provider_config.metadata_url })
            let i
            for (i = 0; i < this.props.ferderation.identity_provider_config.certificates.length; i++) {
              const thumbPrint = this.props.ferderation.identity_provider_config.certificates[i].thumb_print ? this.props.ferderation.identity_provider_config.certificates[i].thumb_print : ''
              this.setState(prevState => ({
                thumbprints: [...prevState.thumbprints, thumbPrint]
              }))
            }
          } else {
            this.setState({ status: 'enabled' })
          }
          this.setState({ loading: false })
        })
      }
    })
  }

  private createOrGetAccountSignIn = (payload: any, federationId: string): Promise<void> => {
    if (this.state.tenant && (this.state.tenant.auth_config == null || this.state.tenant.auth_config.find(item => item.type === 'SAML 2.0') == null)) {
      return this.props.createAccountSignIn(payload)
    } else {
      return this.props.getAccountSignIn(federationId)
    }
  }

  private downloadSp = () : void => {
    if (!this.props.ferderation.isLoading && this.props.ferderation.service_provider_config !== undefined) {
      const { federationProviderId } = this.state
      return this.props.getSpMetadata(federationProviderId).then((response: any) => {
        if (response) {
             const fileName = 'service_provider_metadata_' + this.props.ferderation.name.substring(this.props.ferderation.name.indexOf('_'), this.props.ferderation.name.length) + '.xml'
             fileDownload(response, fileName)
        }})


    }
  }

  private onClick = () : void => {
    const { match } = this.props
    this.state.tenant && this.state.tenant.system_account ? history.push(RouterConfig.platformSettings.settings)  : history.push(RouterConfig.accounts.details(match.params.id))
  }

  private onSubmit = async (): Promise<void> => {
    const { match } = this.props
    const { isEditMode } = this.state
    const payload = {
      auth_type: 'SAML 2.0',
      account_id: match.params.id,
      disable_standard_login: this.state.isEditMode ? (this.state.standardSignInstatus !== 'enabled') : this.state.disableSigleSIgnOn,
      identity_provider_config: {
        metadata: {
          metadata: this.state.idp_metadata
        },
        certificates: this.state.idp_certificates
      },
      status: this.state.status === 'enabled' ? 'ENABLED' : 'DISABLED'
    }
    await this.props.createIdpMetadata(payload, this.props.ferderation.id, isEditMode)
    this.state.tenant && this.state.tenant.system_account ? history.push(RouterConfig.platformSettings.settings)  : history.push(RouterConfig.accounts.details(match.params.id))
  }

  inputFileOnChange = event => {
    const files = event.target.files
    const reader: FileReader = new FileReader()
    reader.readAsText(files[0])
    let extractedData = ''
    let metadata = ''
    let idpUrl = ''
    let mainNode = ''
    let certType = ''
    let certificateNode = ''

    reader.onload = (event: any) => {
      metadata = event.target.result
      const xml2js = require('xml2js')
      const processors = xml2js.processors
      const parser = xml2js.Parser({
        tagNameProcessors: [processors.stripPrefix]
      })
      // var parser = new xml2js.Parser()
      // var stripPrefix = require('xml2js').processors.stripPrefix;
      parser.parseString(event.target.result, function (err, result) {
        if (err) {
          return
        }
        console.error(err)
        // Extract the value from the data element
        mainNode = result['EntityDescriptor']
        if (!mainNode && result['EntitiesDescriptor']) {
          mainNode = result['EntitiesDescriptor']['EntityDescriptor'][0]
        }
        if (mainNode) {
          if (mainNode['IDPSSODescriptor'][0]['KeyDescriptor']) {
            certType = mainNode['IDPSSODescriptor'][0]['KeyDescriptor'][0].$.use
            if (certType === 'signing') {
              certificateNode = mainNode['IDPSSODescriptor'][0]['KeyDescriptor'][0]
            } else if (mainNode['IDPSSODescriptor'][0]['KeyDescriptor'][1] && mainNode['IDPSSODescriptor'][0]['KeyDescriptor'][1].$.use === 'signing') {
              certificateNode = mainNode['IDPSSODescriptor'][0]['KeyDescriptor'][1]
            }
            if (certificateNode) {
              extractedData = certificateNode['KeyInfo'][0]['X509Data'][0]['X509Certificate'][0].replace('-----BEGIN CERTIFICATE-----', '').replace('-----END CERTIFICATE-----', '').replace(/\n|\r/g, '').replace(/\s+/g, '')
            }
          }
          idpUrl = mainNode['IDPSSODescriptor'][0]['SingleSignOnService'][0].$.Location
        }
      })

      if (!idpUrl) {
        this.setState({ showError: true })
      } else {
        this.setState({ idp_metadata: event.target.result })
        this.setState({ idp_url: idpUrl })
        this.setState({ showDetails: true })
      }

      // SHA-1 on certificate binary data`
      if (extractedData) {
        const forge = require('node-forge')
        const md = forge.md.sha256.create()
        md.start()
        md.update(window.atob(extractedData))
        const digest = md.digest()

        // print as HEX
        const hex = digest.toHex()
        const isPresent = this.state.thumbprints.includes(hex)
        this.setState({ showError: false })
        this.setState({ showDuplicateError: false })
        this.setState({ showCertError: false })
        this.setState({ showCertExpiredError: false })

        if (isPresent) {
          this.setState({ showDuplicateError: true })
        } else {
          const certificates = parseCertificate(extractedData)
          if (certificates.length > 0 && certificates[0].isValid) {
            this.setState(prevState => ({
              idp_certificates: [...prevState.idp_certificates, { certificate: extractedData, common_name: certificates[0].subject.components.commonName, expiration_date: certificates[0].validTo, thumb_print: hex }]
            }))
            this.setState(prevState => ({
              thumbprints: [...prevState.thumbprints, hex]
            }))
            this.setState({ showCertDetails: true })
            this.setState({ showCertError: false })
            this.setState({ showCertExpiredError: false })
          } else if (certificates.length > 0 && !certificates[0].isValid) {
            this.setState({ showCertExpiredError: true })
          } else {
            this.setState({ showCertError: true })
          }
        }
      }
    }
  }
  toggleCheckbox = label => {
    if (this.state.disableSigleSIgnOn) {
      this.setState({ disableSigleSIgnOn: false })
    } else {
      this.setState({ disableSigleSIgnOn: true })
    }
  }
  inputCertOnChange = event => {
    const files = event.target.files
    const reader: FileReader = new FileReader()
    reader.readAsText(files[0])
    let extractedData = ''
    this.setState({ showError: false })
    this.setState({ showDuplicateError: false })
    this.setState({ showCertError: false })
    this.setState({ showCertExpiredError: false })

    reader.onload = (event: any) => {
      extractedData = event.target.result.replace(/\n|\r/g, '').replace('-----BEGIN CERTIFICATE-----', '').replace('-----END CERTIFICATE-----', '')
      const certificates = parseCertificate(extractedData)
      if (certificates.length > 0 && certificates[0].isValid) {
        const forge = require('node-forge')
        const md = forge.md.sha256.create()
        md.start()
        md.update(window.atob(extractedData))
        const digest = md.digest()

        // print as HEX
        const hex = digest.toHex()

        const isPresent = this.state.thumbprints.includes(hex)
        if (isPresent) {
          this.setState({ showDuplicateError: true })
        } else {
          this.setState(prevState => ({
            thumbprints: [...prevState.thumbprints, hex]
          }))
          this.setState(prevState => ({
            idp_certificates: [...prevState.idp_certificates, { certificate: extractedData, common_name: certificates[0].subject.components.commonName, expiration_date: certificates[0].validTo, thumb_print: hex }]
          }))

          this.setState({ showCertDetails: true })
        }
      } else if (certificates.length > 0 && !certificates[0].isValid) {
        this.setState({ showCertExpiredError: true })
      } else {
        this.setState({ showCertError: true })
      }
    }
  }
  private onSiteChanged = () : void => {
    if (this.state.status === 'enabled') {
      this.setState({ status: 'disabled' })
    } else {
      this.setState({ status: 'enabled' })
    }
  }
  public handleRemove (thumbprint, index) {
    const parsedIdpCerts = this.state.idp_certificates.slice()
    const thumbprints = this.state.thumbprints.slice()
    this.setState({ idp_certificates: parsedIdpCerts.filter(item => item.certificate !== parsedIdpCerts[index].certificate) })
    this.setState({ thumbprints: thumbprints.filter(item => item !== thumbprint) })
    if (this.state.idp_certificates.length === 0) {
      this.setState({ idp_certificates: [] })
      this.setState({ thumbprints: [] })
    }
  }

  public render (): ReactNode {
    const { idp_certificates, idp_url, showDetails,  isEditMode, loading, showError, showDuplicateError, showCertError, showCertExpiredError, showCertDetails, standardSignInstatus, status} = this.state
    return (
      <>
        {!loading &&
        <div className={styles.container}>
          <header className={styles.header}>
            <h1>
              <Translate id={isEditMode ? 'editSingleSignOn.title' : 'singleSignOn.title'}/>
            </h1>
            <p>
              <Translate id='singleSignOn.details'/>
            </p>

            <div className={showDetails || isEditMode ? styles.hide : styles.display + ' ' + styles.warningText}>
              <IncontextBanner
                bannerType="note"
                description={<Translate id='singleSignOn.note' />}
              />
            </div>
          </header>

          <div className={isEditMode ? styles.display : styles.hide}>
            <div className={styles.ssoOptions}>
              <div className={styles.ssoOption}>
                <input id='sso-enabled' type='radio' name='enabled' value='enabled' checked={status === 'enabled'} onChange={this.onSiteChanged}/>
                <label htmlFor='sso-enabled'>
                  <Translate id={'editSingleSignOn.enable'}/>
                </label>
              </div>
              <div className={styles.ssoOption}>
                <input id='sso-disabled' type='radio' name='enabled' value='disabled' checked={status === 'disabled'} onChange={this.onSiteChanged} disabled = {standardSignInstatus === 'disabled'} />
                <label htmlFor='sso-disabled'>
                  <Translate id={'editSingleSignOn.disable'}/>
                </label>
              </div>
            </div>
          </div>

          <div className={styles.section}>
            <div className={styles.caption}>
              <span className={styles.step}>1</span>
              <h3><Translate id='singleSignOn.uploadIdpTitle'/></h3>
            </div>

            <div className={styles.sectionBody}>
              <span className='details-label'><Translate id='singleSignOn.uploadIdpDetails'/></span>

              <div className='mb-30'>
                <div className={styles.browseFileContainer}>
                  <Button buttonType={ButtonType.SECONDARY}>
                    <label className={styles.inputFileLabel}>
                      <Translate id={showDetails || isEditMode ? 'singleSignOn.reuploadIdpBtn' : 'singleSignOn.uploadIdpBtn'}/>
                      <input
                        className={styles.inputFileInput}
                        type='file'
                        onChange={(e) => this.inputFileOnChange(e)}
                        accept={'.xml'}
                      />
                    </label>
                  </Button>
                </div>

                <div id='error' className={showError ? styles.display : styles.hide}>
                  <span className='error'><Translate id={'singleSignOn.invalidXml'}/></span>
                </div>

                <div id='certDetails' className={showDetails || isEditMode ? styles.display : styles.hide}>
                  <div className='mb-30'>
                    <div>
                      <span className='details-label'><Translate id={'singleSignOn.idpMetadataDetails'}/></span>
                    </div>
                    <div>
                      <span className='details-label'><Translate id={'singleSignOn.idpUrl'}/></span>
                      {
                        idp_url
                      }
                    </div>
                  </div>
                </div>

                <div id='certDetails' className={showDetails || isEditMode ? styles.display : styles.hide}>
                  <div className='mb-30'>
                    <div className={styles.browseFileContainer}>
                      <Button buttonType={ButtonType.SECONDARY}>
                        <label className={styles.inputFileLabel}>
                          <Translate id={'singleSignOn.uploadCertBtn'}/>
                          <input
                            className={styles.inputFileInput}
                            type='file'
                            onChange={(e) => this.inputCertOnChange(e)}
                          />
                        </label>
                      </Button>
                    </div>
                    <div id='error' className={showDuplicateError ? styles.display : styles.hide}>
                      <span className='error'><Translate id={'singleSignOn.duplicateCertificate'}/></span>
                    </div>
                    <div id='error' className={showCertError ? styles.display : styles.hide}>
                      <span className='error'> <Translate id={'singleSignOn.invalidCertificate'}/></span>
                    </div>
                    <div id='error' className={showCertExpiredError ? styles.display : styles.hide}>
                      <span className='error'><Translate id={'singleSignOn.expiredCertificate'}/></span>
                    </div>

                    <div id='certDetails' className={showCertDetails || isEditMode ? styles.display : styles.hide}>
                      {idp_certificates.map((certificate, index) => (
                        <div id={index.toString()} key={index}>
                          <div>
                            <span className='details-label'><Translate id={'singleSignOn.certificateDetails'}/></span>
                            <a href="#" onClick={(e) => {
                              e.preventDefault()
                              this.handleRemove(certificate.thumb_print, index)
                            }}
                            >
                              <DeleteOutlined />
                            </a>
                          </div>
                          <div>
                            <span className='details-label'><Translate id={'singleSignOn.commonName'}/></span>
                            {
                              certificate.common_name
                            }
                          </div>
                          <div>
                            <span className='details-label'><Translate id={'singleSignOn.expiryDate'}/></span>
                            {
                              certificate.expiration_date
                            }
                          </div>
                          <div>
                            <span className='details-label'><Translate id={'singleSignOn.thumbprint'}/></span>
                            {
                              certificate.thumb_print
                            }
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.section}>
            <div className={styles.caption}>
              <span className={styles.step}>2</span>
              <h3>
                <Translate id='singleSignOn.downloadIdpTitle' />
              </h3>
            </div>

            <div className={styles.sectionBody}>
              <span className='details-label'><Translate id='singleSignOn.downloadIdpDetails'/></span>
              <div>
                <span className='details-label'><Translate id='singleSignOn.ssoUrl' />: </span>
                {this.props.ferderation.service_provider_config ? this.props.ferderation.service_provider_config.acs_url : ''}
                <CopyToClipboard text={this.props.ferderation.service_provider_config ? this.props.ferderation.service_provider_config.acs_url : ''}>
                  <div className={styles.copyToClipboard}>
                    <FontAwesomeIcon size='lg' icon={faCopy} />
                  </div>
                </CopyToClipboard>
              </div>
              <div className='mb-30'>
                <div className={styles.actions}>
                    <Button
                        buttonType={ButtonType.SECONDARY}
                        onClick={this.downloadSp}>
                        <Translate id='singleSignOn.downloadIdpBtn' />
                    </Button>
                </div>
              </div>

              <div className={styles.actions}>
                <Button buttonType={ButtonType.SECONDARY} onClick={this.onClick}>
                  <Translate id='common.form.buttons.cancelBtn'/>
                </Button>
                <Button onClick={this.onSubmit}>
                  <Translate id='common.form.buttons.saveBtn' />
                </Button>
              </div>
            </div>

          </div>
        </div>
        }
        {loading &&
        <div className='spin-wrap'><Spin /></div>
        }
      </>
    )
  }
}
export default connect(
  (state: IAppState, ownProps: CreateAccountSignInOwnProps): CreateAccountSignInStateProps => {
    return {
      ferderation: state.federation,
      tenant: state.tenants.items.find(item => item.id === ownProps.match.params.id)

    }
  },
  { createAccountSignIn, createIdpMetadata, getAccountSignIn, fetchTenantById, getSpMetadata }
)(CreateAccountSignIn)
